import React, { useState, useRef } from "react";
import { motion } from "framer-motion";
import Me2 from "../assests/Me2.png";
import me from "../assests/me.jpg";



export default function Home() {
  return (
     <Box />
  );
}



const draw = {
  hidden: { pathLength: 0, opacity: 0 },
  visible: (i) => {
    const delay = 1 + i * 0.5;
    return {
      pathLength: 1,
      opacity: 1,
      transition: {
        pathLength: { delay, type: "spring", duration: 1.5, bounce: 0 },
        opacity: { delay, duration: 0.01 }
      }
    };
  }
};

function getRelativeCoordinates(event, referenceElement) {
  const position = {
    x: event.pageX,
    y: event.pageY
  };

  const offset = {
    left: referenceElement.offsetLeft,
    top: referenceElement.offsetTop,
    width: referenceElement.clientWidth,
    height: referenceElement.clientHeight
  };

  let reference = referenceElement.offsetParent;

  while (reference) {
    offset.left += reference.offsetLeft;
    offset.top += reference.offsetTop;
    reference = reference.offsetParent;
  }

  return {
    x: position.x - offset.left,
    y: position.y - offset.top,
    width: offset.width,
    height: offset.height,
    centerX: (position.x - offset.left - offset.width / 2) / (offset.width / 2),
    centerY: (position.y - offset.top - offset.height / 2) / (offset.height / 2)
  };
}

const Box = () => {
  const [mousePosition, setMousePosition] = useState({});
  const boxRef = useRef();
  const [prevMouse,setPreviousPosition] = useState({});
  const handleMouseMove = e => {
    setMousePosition(getRelativeCoordinates(e, boxRef.current));
  };
  const css = {
    box: {
      backgroundColor: "transparent",
      width: "100%",
      height: "100%",
      position: "relative",
      overflow:"hidden"
    },
    fly: {
      position: "absolute",
      width: "15px",
      height: "15px",
      margin: "-10px",
      backgroundColor: "#22C3E6",
      borderRadius:10,
      
    }
  };
  return (
    <motion.div
      ref={boxRef}
      style={{ ...css.box, perspective: 600 }}
      onMouseMove={e => handleMouseMove(e)}
      animate={{
        rotateX: mousePosition.centerX * 20,
        rotateY: mousePosition.centerY * 20
      }}
    >
      <motion.div
        style={css.fly}
        animate={{
          x: mousePosition.x,
          y: mousePosition.y
        }}
      />
      <motion.div
        style={{ ...css.fly, backgroundColor: "#A4FCFA" }}
        animate={{
          x: mousePosition.x,
          y: mousePosition.y
        }}
        transition={{ type: "spring" }}
      />
      <motion.div
        style={{ ...css.fly, backgroundColor: "#C2EFDF" }}
        animate={{
          x: mousePosition.x,
          y: mousePosition.y
        }}
        transition={{ type: "tween" }}
      />
     <>
     <div className="home-container" id="first">
     <div class="container">
            <section class="animation">
                <div class="first"><div>print</div></div>
                <div class="second"><div>console.log</div></div>
                <div class="third"><div>System.out.println</div></div>
            </section>
            <p>("Hello 👋 I'm")</p>
        </div>
        <div className="home-container-with-image">
        <div className="home-content" >
        <div className="home-Name">
            <h1>Sai Manjunath</h1>
        </div>
            <p>A dedicated software developer with a strong passion for solving complex challenges. With a solid background in computer science and a keen eye for detail, I focus on creating innovative software, whether it's user-friendly applications or finely tuned algorithms. My insatiable curiosity drives me to explore new technologies, ensuring my work stays on the cutting edge. Let's team up to bring your software dreams to life!</p>
        </div>
        {/* <div className="home-image">
            <div className="home-img">
            <img src = {me} />
            </div>
        </div> */}
        </div>
        </div>
     </>
    </motion.div>
  );
};
