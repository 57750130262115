import React,{useState,useEffect} from "react"
import mainLogo from "../assests/main_logo.png"
export default function Header(){
    return (
        <>
        <div className="header-container">
        {/* <div className="header-logo">
        <img src = {mainLogo} ></img>
        </div> */}
        </div>
        </>
    )
}